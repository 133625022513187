
export default {
  props: {
    label: {
      type: String,
      default: ''
    }
  },

  setup: () => {
    return {

    }
  }
}
